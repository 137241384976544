.statBlockCard {
    width: 100% !important;
}

.statBlockTable {
    border: none !important;
    text-align: center !important;
}

.statBlockTable td {
    margin: 0;
    padding: 0.25em 0 !important;
    border: none !important;
}

div.bar>.progress {
    right: auto !important;
    left: 50% !important;
}

.healthBar {
    display: flex;
}

div.healthBar>button {
    margin: 0 !important;
}

div.healthBar>div {
    margin: 0 !important;
    width: 100%;
    background-color: rgb(255, 60, 60) !important;
}

.ui.progress .bar {
    height: 100% !important;
}

.ui.progress[data-percent="0"] .bar {
    background: transparent;
}

.ui.progress[data-percent="0"] .bar .progress {
    color: rgba(0, 0, 0, 0.87);
}

.absRight {
    position: absolute;
    right: 0.25em;
}

.absLeft {
    position: absolute;
    left: 0.25em;
}

.margin0 {
    margin: 0 !important;
}

.editArea {
    padding-top: 0.25em;
    margin: auto !important;
    width: 80%;
}

.hide {
    display: none;
}

.rotate {
    transform: rotate(180deg);
    transition: transform 150ms ease;
}

div[role="healthWheel"] {
    --size: 90px;
    --fg: #21ba45;
    --bg: rgb(255, 60, 60);
    --pgPercentage: var(--value);
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background:
        conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0);
    color: var(--fg);
    transform: scale(-1, 1);
    float: left;
}

div[role="healthWheel"]>div {
    margin: 5px;
}

.draggable {
    cursor: e-resize;
}

.highlight {
    border: 1px solid black !important;
}

.iniativeContainer::-webkit-scrollbar {
    height: 0.3em;
}

.iniativeContainer::-webkit-scrollbar-thumb {
    background-color: #ddd;
}

.iniativeContainer::-webkit-scrollbar-track {
    background-color: #fff;
}

.iniativeContainer {
    display: flex !important;
    flex-wrap: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    height: max-content;
    padding-top: 0.3em;
}

.iniativeCard {
    flex: 0 0 auto;
    width: 10em !important;
    height: 3em;
    margin: 0.5em 0.25em !important;
}

.iniativeCard>.content {
    padding: 0.5em !important;
}

.scrollCover {
    position: absolute;
    background: #fff;
    pointer-events: none;
    width: 100%;
    height: 0.4em;
    bottom: -1px;
    -webkit-transition: all .4s;
    transition: all .4s;
    opacity: 1;
}

.scrollCover.hidden {
    opacity: 0;
}

.addStatBlock {
    position: fixed;
    bottom: 10px;
    right: 5px;
}

.addStatBlock.front {
    color: #21ba45;
}

.addStatBlock.back {
    color: white;
}